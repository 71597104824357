@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@200;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@200;500;700&display=swap');

:root{
  --myFont: 'MuseoModerno', system-ui;
}


body {
  margin: 0;
  font-family: Poppins;
  background-color: #010824;
  color: #eee;
  font-size: 15px;
  min-height: 100vh;
  background-image: repeating-linear-gradient(
    to right, transparent 0 49px, #eee1 49px 50px
  ),
  repeating-linear-gradient(
    to bottom, transparent 0 49px, #eee1 49px 50px
  );
  &::before{
    position: fixed;
    width: 400px; 
    height: 400px;
    content: '';
    background-image: linear-gradient(
      to right, #d02e23, #7a43b6
    );
    z-index: -1;
    top: -200px;
    left: calc(50% - 200px);
    border-radius: 50% 50% 0 0;
    pointer-events: none;
    filter: blur(150px)
  }
}

main {
  width: min(1300px, 100%);
  margin: auto;
}

/* header */
header{
  position: fixed;
  width: min(1300px, 100%);
  height: 60px;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 100;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid #6078ff;
  border-left: 1px solid #6078ff;
  border-top: 1px solid #6078ff60;
  border-right: 1px solid #6078ff60;
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  & img{
    width: 50px;
    border-radius: 100%;
  }
  & .logo, nav {
    display: flex;
    gap: 30px;
  
    & span {
      cursor: pointer;
      position: relative; /* Make the span a containing block for the pseudo-element */
  
      &.active {
        color: #6078ff;
        &:hover {
          text-shadow: 0 0 5px #6078ff;
        }
      }
  
      &:hover {
        text-shadow: 0 0 5px #fff;
      }
  
      &::before {
        content: "";
        position: absolute; /* Corrected typo */
        display: block;
        width: 100%;
        height: 2px;
        bottom: -2px; /* Adjust to place the underline below the text */
        left: 0;
        background-color: #6078ff;
        transform: scaleX(0);
        transform-origin: center; /* Ensure the underline animates from the center */
        transition: transform 0.3s ease;
      }
  
      &:hover::before {
        transform: scaleX(1);
      }
    }
  }
  
}

section {
  width:min(1300px, 100%);
  margin: auto;
  padding: 50px;
  box-sizing: border-box;
  &.home{
    padding-top: 150px;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    justify-content: space-between;
    align-items: center;
    & .content{
      font-family: var(--myFont);
      & .name{
        font-size: 7em;
        font-weight: bold;
        line-height: 1em;
        & span{
          background-image: linear-gradient(
            to right, #7be728, #f3265f, #7f40ac,#7be728
          );
          background-size: 200% auto;
          animation: gradientAnimation 5s linear infinite;
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
        }
      }
      & .des{
        color: #eee5;
        margin: 30px 0;
        border-left: .7em solid #6078ff;
        padding-left: 2em;
      }
      
      & a.buttonLink {
        color: #eee;
        font-size: large;
        font-weight: bold;
        text-decoration: none;
        border: 1px solid #6078ff;
        padding: 20px 30px;
        border-radius: 40px;
        margin-top: 30px;
        display: block;
        width: max-content;
        transition: 0.5s;
        &:hover {
          box-shadow: 5px 10px 10px #6078ff90;
        }
      }
      & a.normalLink{
        color: #eee;
        text-decoration: none;
      }
    }
    & .avatar{
      text-align: right;
      & .card{
        display: inline-flex;
        flex-direction: column;
        width: min(100%, 400px);
        box-shadow: 0 150px 150px #6078ff40;
        transition: 0.5s;
        &:hover{
          box-shadow: 0 150px 150px #6078ff90;
        }
        & img{
          width: 100%;
          height: min(700px, 50vh);
          object-fit: cover;
          object-position: top;
          clip-path: polygon(81% 0, 100% 18%, 100% 100%, 0 100%, 0 0);
        }
        & .info{
          background-color: #eee;
          color: #010824;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          text-align: center;
          gap: 20px;
          padding: 20px;
          font-size: 1.2em;
          & div:nth-child(-n + 3){
            font-weight: bold;
          }
        }
      }
    }
  }
  &.skills{
    & .title{
      text-align: center;
      font-size: 7em;
      font-family: var(--myFont);
    }
    & .des{
      color: #eee5;
      text-align: center;
      max-width: 500px;
      margin: auto;
    }
    & .list{
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(auto-fill, 300px);
      justify-content: space-between;
      gap: 3vw;
      position: relative;
      &::before{
        position: absolute;
        content:'';
        width: 70%;
        height: 70%;
        background-image: linear-gradient(
          -45deg, red, #6078ff
        );
        animation: gradientAnimation45 10s linear infinite;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 20px;
        background-size: 200% auto;
      }
      & .item {
        backdrop-filter: blur(50px);
        padding: 20px;
        border-radius: 20px;
        background-color: #01082488;
        & svg{
          font-size: 30px;
          background-color: #eee2;
          padding: 10px;
          border-radius: 10px;
        }
        & .des {
          text-align: left;
          width: 100%;
          font-size: small;
        }
      }
    }
  }
  &.projects{
    & .title{
      font-size: 7em;
      text-align: center;
      font-family: var(--myFont);
      margin-top: 0px;
    }
    & .des {
      color: #eee5;
      text-align: center;
      max-width: 500px;
      margin: auto;
    }
    & .list {
      margin-top: 50px;
      & .item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        gap: 20px;
        & .images{ 
          background-image: linear-gradient(
            -45deg, #6078ff, #be24a9
          );
          background-size: 200% auto; /* Ensure the gradient has room to animate */
          animation: gradientAnimation45 10s linear infinite;
          padding: 45px;
          text-align: center;
          border-radius: 20px;
          overflow: hidden;
          & img {
            height: auto;
            width: 100%;
            border-radius: 20px;
            transition: 0.5s;
            &:hover{
              scale: 1.15;
            }
          }
        }
        & .content{
          & h3 {
            font-size: 3em;
            margin: 0;
          }
          & .des {
            text-align: left;
            width: 100%;
          }
          & .mission{
            display: grid;
            grid-template-columns: 70px 1fr;
            align-items: center;
            gap: 10px;
            margin-top: 10px;
            & div:nth-child(1) svg{
              background-color: #eee3;
              padding: 20px;
              font-size: 30px;
              border-radius: 10px;
            }
          }
        }
        &:nth-child(2n){
          & .images{
            grid-column-start: 2;
          }
          & .content{
            grid-column-start: 1;
            grid-row-start: 1;
          }
        }
      }
    }
  }
  &.contacts{
    padding-bottom: 120px;
    & .title {
      font-size: 7em;
      text-align: center;
      font-family: var(--myFont);
    }
    & .des {
      color: #eee5;
      text-align: center;
      max-width: 500px;
      margin: auto;
    }
    & .list{
      text-align: center;
      & .item {
        margin-top: 50px;
        & .contactLink {
          & a {
            text-decoration: none;
            color: #6078ff;
          }
        }
      }
    }
  }
}

.animation {
  transform: translateY(50px);
  filter: blur(20px);
  opacity: 0;
  transition: 0.5s;
  &.active{
    transform: translateY(0);
    filter: blur(0);
    opacity: 1;
  }
}

.icon-bar {
  display: None;
}

@keyframes gradientAnimation {
  to{
    background-position: 200% center;
  }
}

@keyframes gradientAnimation45 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 767px){
  section{
    padding: 20px;
    &.home{
      grid-template-columns: 1fr;
      padding-top: 70px;
      & .content{
        & .name{
          font-size: 3em;
        }
        & a{
          margin: auto;
          font-size: small;
        }
      }
      & .avatar{
        grid-row-start: 1;
      }
    }
    &.skills{
      & .title{
        font-size:3em;
      }
      & .list{
        grid-template-columns: 1fr;
      }
    }
    &.projects{
      & .title{
        font-size:3em;
      }      
      & .list{
        & .item{
          grid-template-columns: 1fr;
          & .content{
            & h3 {
              font-size: 1.2em;
            }
          }
          &:nth-child(2n){
            & .content, .images {
              grid-column-start: auto;
              grid-column-end: auto;
              grid-row-start: auto;
            }
          }
        }
      }
    }
    &.contacts{
      & .title{
        font-size:3em;
      }
    }
  }
  header {
    & .icon-bar{
      display: block;
      padding-right: 1em;
    }
    nav {
      position: fixed;
      background-color: #eee;
      width: 80%;
      height: 100vh;
      inset: 0 auto 0 0;
      color: #010824;
      flex-direction: column;
      padding: 50px;
      box-sizing: border-box;
      left: -100%;
      transition: 0.5s;
      &.active{
        left: 0;
      }
    }
  }
}

@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    section{
      &.skills {
        & .list {
          display: grid;
          grid-template-columns: repeat(auto-fill, 400px);
          justify-content: space-between;
        }
      }
    }
}

